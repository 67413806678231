import workout0 from '../assets/workout0.jpg';
import workout1 from '../assets/workout1.jpg';
import workout2 from '../assets/workout2.jpg';
import workout3 from '../assets/workout3.jpg';
import workout4 from '../assets/workout4.jpg';
import workout5 from '../assets/workout5.jpg';
import workout6 from '../assets/workout6.jpg';
import workout7 from '../assets/workout7.jpg';
import workout8 from '../assets/workout8.jpg';
import workout9 from '../assets/workout9.jpg';
import workout10 from '../assets/workout10.jpg';
import workout11 from '../assets/workout11.jpg';
import workout12 from '../assets/workout12.jpg';
import workout13 from '../assets/workout13.jpg';
import workout14 from '../assets/workout14.jpg';

export const workoutImages = [
  workout0,
  workout1,
  workout2,
  workout3,
  workout4,
  workout5,
  workout6,
  workout7,
  workout8,
  workout9,
  workout10,
  workout11,
  workout12,
  workout13,
  workout14,
];
