export const trackEvent = (
  eventName,
  eventLabel,
  eventCategory,
  nonInteraction
) => {
  window.gtag &&
    window.gtag('event', eventName, {
      event_label: eventLabel,
      event_category: eventCategory,
      non_interaction: nonInteraction,
    });

  window.heap && window.heap.track(eventLabel);
};

export const trackPageView = (pageTitle, pageLocation, pagePath) => {
  window.gtag &&
    window.gtag('event', 'page_view', {
      page_title: pageTitle,
      page_location: pageLocation,
      page_path: pagePath,
    });
};

export const identifyUser = (username, name, email) => {
  window.heap && window.heap.identify(username);
  window.heap &&
    window.heap.addUserProperties({
      Username: username,
      Name: name,
      Email: email,
    });
};
