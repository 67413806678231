export const equipmentsTemplate = [
  'Somente o Corpo',
  'Halteres',
  'Kettlebell',
  'Barra de Supino',
  'Bola de Peso',
  'Barra de Peso Fixo',
  'Barra de Anilhas Ajustáveis',
  'Anilhas de Peso',
  'Banco de Musculação Fixo',
  'Banco de Musculação Ajustável',
  'Barra de Porta',
  'Estação de Musculação',
  'Faixas de Resistência',
  'Elásticos de Treinamento',
  'Bola de Exercício',
  'Tapete de Exercícios',
];

export const equipmentsTemplateEn = [
  'Bodyweight Only',
  'Dumbbells',
  'Kettlebell',
  'Barbell',
  'Weight Ball',
  'Fixed Weight Bar',
  'Adjustable Dumbbell Bar',
  'Weight Plates',
  'Fixed Weight Bench',
  'Adjustable Weight Bench',
  'Door Bar',
  'Strength Training Station',
  'Resistance Bands',
  'Training Bands',
  'Exercise Ball',
  'Exercise Mat',
];

export const muscularGroupsTemplate = [
  'Variados',
  'Braços',
  'Bíceps',
  'Tríceps',
  'Ombros',
  'Costas',
  'Peito',
  'Pernas',
  'Glúteos',
  'Abdominais',
  'Lombar',
  'Antebraços',
  'Panturrilhas',
];

export const muscularGroupsTemplateEn = [
  'Various',
  'Arms',
  'Biceps',
  'Triceps',
  'Shoulders',
  'Back',
  'Chest',
  'Legs',
  'Glutes',
  'Abdominals',
  'Lower Back',
  'Forearms',
  'Calves',
];

export const trainingNames = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
